<template>
  <div id="app">
    <router-view />
  </div>
</template> 
<script> 
export default {
  name: 'app',
  created () {
    //!判断一下当前的url 当前的和要跳的一样就不跳 
    // let move = "http://xinda.mobile.netloop.cn/home"
    // let pc = "http://xinda.netloop.cn/home"
    // let url = window.location.href;
    // if (this._isMobile()) {
    //   console.log("移动端");
    //   if (url.indexOf("mobile") == -1) {
    //     location.href = move
    //   }
    // }

  },
  methods: {
    //移动端  pc判断 
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>
<style lang="less">
#app {
  width: 100%;
  background-color: #f4f8f9;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  border-radius: 4px;
}
</style> 
